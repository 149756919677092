@import 'src/core/styles/media';
@import 'src/core/styles/mixins';
@import 'src/styles/sizes';

.user-form-root {
  width: 100%;
  max-width: 512px;

  @include media(desktop) {
    width: calc(100% - #{$usersListWidth} - 1em);
    max-width: initial;
  }

  & > .user-form {
    width: 100%;
    max-width: initial;
  }

  .user-form-row {
    margin-top: 0.25em;
    padding: 0.25em 0;
    width: 100%;

    @include flexBox(row, nowrap, space-between, center);

    &:first-child {
      margin-top: 0;
    }

    .user-form-label {
      display: inline-block;
      width: 7rem;
      margin-right: 1rem;
    }

    .user-form-value {
      background-color: $transparentDark30;
      border-radius: 0.25em;
      display: inline-block;
      font-size: 0.85rem;
      overflow: hidden;
      padding: 0.5em 0.5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 8rem);
    }
  }
}

.toolbar-button {
  white-space: nowrap;
  width: initial;
}

.user-form-modal {
  background-color: $transparentDark50;
  color: $white;
}
