@import 'src/core/styles/mixins';

.menu {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  &-item {
    background-color: transparent;
    border-radius: 0.25rem;
    padding: 0 8px 8px 0;
    width: 100%;
  }
}
