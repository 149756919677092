@import 'src/core/styles/mixins';

.auth-form {
  .change-password-button {
    margin-top: 1rem;
    width: 100%;
  }

  &__mode-switcher {
    background-color: transparent;
    color: $transparentWhite50;
    cursor: pointer;
    display: inline-block;
    font-size: 0.85rem;
    margin-top: 1rem;
    outline: none;
    transition: color 100ms;

    &:hover {
      color: $white;

      @include textShadow(2px, 2px, 5px, $transparentWhite75);
    }

    &:active {
      text-shadow: none;
    }
  }
}