@import 'src/core/styles/mixins';

.form {
  background-color: $lightGrey;
  border-radius: 0.25rem;
  padding: 2rem;
  position: relative;
  width: 22rem;
  max-width: calc(100% - 2rem);

  @include flexBox(column, nowrap, space-between);

  .cross-wrapper {
    color: $transparentWhite75;
    font-size: 1.25rem;
    height: 1.25rem;
    outline: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
    transition: color 100ms;
    width: 1.25rem;

    &:hover {
      color: $white;
    }

    &:active,
    &:focus {
      color: $golden;
    }

    & > svg {
      cursor: pointer;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
