@import 'src/core/styles/mixins';

.gallery-item {
  border: 1px solid $transparentWhite50;
  border-radius: 0.25rem;
  color: $transparentWhite50;
  height: 10rem;
  padding: 1em;
  width: 10rem;
  transition: color 300ms, border-color 300ms;

  &:hover {
    border-color: $white;
    color: $white;
    box-shadow: 2px 2px 5px $white;
  }

  &:active {
    box-shadow: none;
  }

  &__link {
    color: inherit;
    height: 100%;
    text-decoration: none;
    width: 100%;

    @include flexBox(column, nowrap, space-around, center);
  }

  &__icon-wrapper {
    max-height: 5rem;
    width: 100%;

    @include flexBoxCentered();
  }

  &__label-wrapper {

  }
}
