$mobile-max-width: 400px;
$tablet-min-width: $mobile-max-width + 1;
$tablet-max-width: 720px;
$desktop-min-width: $tablet-max-width + 1;

@mixin media ($type) {
  @if $type == mobile {
    @media only screen and (max-width: $mobile-max-width) {
      @content;
    }
  } @else if $type == tablet {
    @media only screen and (min-width: $tablet-min-width) {
      @content;
    }
  } @else if $type == desktop {
    @media (min-width: $desktop-min-width) {
      @content;
    }
  }
}
