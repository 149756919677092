@import 'src/core/styles/mixins';

.header {
  align-items: center;
  background-color: $darkGrey;
  box-shadow: 0 2px 5px $transparentWhite30;
  color: white;
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 1rem;
  transition: padding 300ms;
  width: 100%;

  &__toolbar-button {
    height: calc(10px + 4vmin + 0.75rem);
    width: calc(10px + 4vmin + 0.75rem);

    @include basicButton();
  }

  &__title {
    font-size: calc(10px + 4vmin);
    opacity: 0;

    @include show(500ms);
  }

  @media screen and (min-width: 721px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
