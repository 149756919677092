@import 'src/core/styles/mixins';
@import 'src/core/styles/media';
@import 'src/styles/mixins';

.admin-users {
  @include adminContent();

  @include media(desktop) {
    @include flexBox(row, nowrap, space-between, flex-start);
  }
}
