@import 'src/core/styles/mixins';

.content {
  flex: 1;
  opacity: 0;
  padding-top: 1.5rem;
  position: relative;
  width: 100%;

  @include flexBox(column, nowrap, flex-start, center);
  @include show(1000ms);
}
