@import 'src/core/styles/mixins';

.form-button {
  margin-right: 1rem;
  width: 6rem;

  @include borderedButton();

  &--disabled,
  &--disabled:hover,
  &--disabled:active {
    cursor: default;
    color: $transparentWhite50;
    background-color: $transparent;
    box-shadow: none;
  }

  &:last-child {
    margin-right: 0;
  }
}
