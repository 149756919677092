@import 'src/core/styles/media';
@import 'src/core/styles/mixins';
@import 'src/styles/sizes';

.users-list-root {
  border-right: none;
  height: 100%;
  overflow-y: auto;
  padding-right: 0.5em;
  width: 100%;

  @include media(desktop) {
    border-right: 1px solid $transparentWhite30;
    width: $usersListWidth;
  }

  .users-list {
    width: 100%;

    &-item {
      margin-top: 0.5em;

      @include menuItemAction();
      @include flexBox(row, nowrap, space-between, center);

      &:first-child {
        margin-top: 0;
      }

      &--selected {
        background-color: $transparentWhite75;
        color: $transparentDark75;

        .users-list-item__status {
          color: $red;
        }
      }

      &__status {
        color: $transparentDark50;
        font-size: 0.85rem;
      }

      &:hover {
        .users-list-item__status {
          color: $red;
        }
      }
    }
  }
}
