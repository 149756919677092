@import 'src/core/styles/mixins';

.captcha {
  width: 100%;
  height: 48px;

  @include flexBox(row, nowrap, space-between, center);

  &-label {
    margin-right: 1rem;
  }

  &-field {
    width: 128px;
    height: 48px;
    flex: 0 1 auto;
    border-radius: 4px;
    border: 1px solid $white;
  }
}
