$lightBlue: rgba(135, 206, 255, 1);

$darkGrey: rgba(40, 44, 52, 1);
$lightGrey: rgba(108, 123, 139, 1);
$blueGrey: rgba(159, 182, 205, 1);

$golden: rgba(255, 215, 0, 1);
$darkBrown: rgba(105, 105, 105, 1);
$white: rgba(255, 255, 255, 1);
$red: rgba(255, 0, 0, 1);
$green: rgba(0, 139, 0, 1);
$blue: rgba(24, 116, 205, 1);
$yellow: rgba(255, 255, 0, 1);
$black: rgba(0, 0, 0, 1);

$transparent: rgba(0, 0, 0, 0);
$transparentDark30: rgba(0, 0, 0, 0.3);
$transparentDark50: rgba(0, 0, 0, 0.5);
$transparentDark75: rgba(0, 0, 0, 0.75);
$transparentWhite30: rgba(255, 255, 255, 0.3);
$transparentWhite50: rgba(255, 255, 255, 0.5);
$transparentWhite75: rgba(255, 255, 255, 0.75);
